import {
    loadTask,
    applyTaskSubmission,
    getTaskSubmission,
} from "./pumpRoomService";
import config from "../config";
import WindowUtils from "../lib/windowUtils";
import {
    PumpingTask,
    ProfileInput,
    LoadTaskInput,
    TaskSubmissionInput,
    TaskSubmissionOutput,
    IDEState,
    LoadTaskServiceParams,
    ApplySubmissionServiceParams,
} from "../types";

const windowUtils = new WindowUtils();

export const loadTaskFromBE = async (
    params: LoadTaskServiceParams,
    userProfile: ProfileInput | null,
    abortSignal: AbortSignal,
    onLoadingStart: () => void,
    onLoadingEnd: (ideState: IDEState) => void,
    onLoadingError: () => void,
) => {
    onLoadingStart();
    if (params.hangOnLoad) {
        console.warn("Imitating hanging on task load");
        return;
    }
    const loadTaskInput: LoadTaskInput = {
        repo_name: params.repoName,
        task_name: params.taskName,
        ignore_state: params.ignoreState,
        profile: userProfile,
        page_url: userProfile?.page_url || windowUtils.getHref(),
    };
    try {
        var currentTask: PumpingTask | undefined = undefined;
        var taskSubmission: TaskSubmissionOutput | undefined = undefined;
        var selectedFilePath: string | undefined = undefined;
        var initialTask: PumpingTask | undefined = await loadTask(
            loadTaskInput,
            { abortSignal }
        );
        // If task loaded successfully, set the initial task and current task
        if (initialTask) {
            currentTask = structuredClone(initialTask);
            if (currentTask.state && !params.ignoreState) {
                const filesStateMap = new Map(
                    currentTask.state.files.map((fileState) => [
                        fileState.path,
                        fileState,
                    ])
                );
                currentTask.files = currentTask.files.map((file) =>
                    filesStateMap.has(file.path)
                        ? filesStateMap.get(file.path)!
                        : file
                );
                if (
                    currentTask.files.some(
                        (file) =>
                            file.path === currentTask!.state!.selected_file_path
                    )
                ) {
                    selectedFilePath = initialTask.state!.selected_file_path;
                }
                taskSubmission = initialTask.state!.task_submission;
            }
            if (!selectedFilePath) {
                selectedFilePath = currentTask.selected_file || undefined;
            }
        }
    } catch (error) {
        onLoadingError();
    } finally {
        const ideState: IDEState = {
            taskSubmission: taskSubmission,
            initialTask: initialTask,
            currentTask: currentTask,
            selectedFile: selectedFilePath,
        }
        onLoadingEnd(ideState);
    }
};

export const applySubmissionToBE = async (
    params: ApplySubmissionServiceParams,
    currentTask: PumpingTask | null,
    selectedFile: string | null,
    userProfile: ProfileInput | null,
    onApplyingStart: () => void,
    onApplyingSubmission: (submission: TaskSubmissionOutput) => void,
    onApplyingEnd: (submission: TaskSubmissionOutput) => void,
    onApplyingError: () => void,
) => {
    if (!currentTask) {
        return;
    }
    if (params.errorOnApply) {
        console.warn("Imitating error on task submission");
        onApplyingError();
        return;
    }
    if (!currentTask.files || !currentTask.files.length || !selectedFile) {
        onApplyingError();
        return;
    }
    const taskSubmissionInput: TaskSubmissionInput = {
        repo_task_id: currentTask.id,
        files: currentTask.files,
        selected_file_path: selectedFile,
        profile: userProfile,
        ui_version: config.appVersion,
        page_url: userProfile?.page_url || windowUtils.getHref(),
    };
    onApplyingStart();
    if (params.hangOnApply) {
        console.warn("Imitating hanging on task submission");
        return;
    }
    let submission: TaskSubmissionOutput;
    try {
        submission = await applyTaskSubmission(taskSubmissionInput);
    } catch (error) {
        onApplyingError();
        return
    }
    onApplyingSubmission(submission);

    const submissionUID: string = submission.uid;
    const maxAttempts: number = config.taskSubmissionTimeout / config.taskCheckStatusInterval;
    let attempts: number = 0;

    while (!submission.ready && attempts < maxAttempts) {
        await new Promise((resolve) => setTimeout(resolve, config.taskCheckStatusInterval));
        try {
            submission = await getTaskSubmission(submissionUID);
            if (submission.ready) {
                break;
            }
        } catch (error) {
            onApplyingError();
            break;
        }
        attempts++;
        if (attempts >= maxAttempts) {
            onApplyingError();
            break;
        }
    }
    onApplyingEnd(submission);
};
