import WindowUtils from './windowUtils';
import { TaskQueryParams } from '../types';

export const getTaskQueryParams = (): TaskQueryParams => {
    const windowUtils = new WindowUtils();
    const searchParams = windowUtils.getSearchParams();
    return {
        repoName: searchParams.get("repo_name") || undefined,
        taskName: searchParams.get("task_name") || undefined,
        errorOnLoad: searchParams.get("error_on_load") === "1",
        errorOnApply: searchParams.get("error_on_apply") === "1",
        hangOnLoad: searchParams.get("hang_on_load") === "1",
        hangOnApply: searchParams.get("hang_on_apply") === "1",
        demoUser: searchParams.get("demo_user") === "1",
        ignoreState: searchParams.get("ignore_state") === "1",
    };
};
