import { createTheme } from "@mui/material/styles";

// Define custom scrollbar styles
const scrollbarStyles = {
    "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "#f0f0f0",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#3f51b5",
        borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#002984",
    },
};

// Create and export the custom theme
const theme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "*": scrollbarStyles,
                body: scrollbarStyles, // Still apply to the body for global scrolling
            },
        },
    },
    // Add other theme customizations like typography, palette, etc.
    palette: {
        // primary: {
        //   main: '#3f51b5',
        // },
        // secondary: {
        //   main: '#f50057',
        // },
        // background: {
        //   paper: '#ffffff',
        // },
    },
});

export default theme;
