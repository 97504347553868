import React from "react";
import "./App.css";
import HomePage from "./components/HomePage";
import TaskPage from "./components/TaskPage";
import { ThemeProvider } from "@mui/material/styles"; // Import ThemeProvider from MUI
import CssBaseline from "@mui/material/CssBaseline"; // Import CssBaseline for global styles
import theme from "./theme"; // Import the custom theme
import { getTaskQueryParams } from "./lib/queryParams";

const App: React.FC = () => {
    const taskParams = getTaskQueryParams();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline /> {/* Apply global styles */}
            {taskParams.repoName && taskParams.taskName ? (
                <TaskPage
                    repoName={taskParams.repoName}
                    taskName={taskParams.taskName}
                />
            ) : (
                <HomePage />
            )}
        </ThemeProvider>
    );
};

export default App;
