interface Config {
    apiBaseUrl: string;
    apiTimeout: number;
    appName: string;
    appVersion: string;
    appEnvironment: 'development' | 'production';
    taskSubmissionTimeout: number;
    taskCheckStatusInterval: number;
}

const config: Config = {
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://pumproom-api.inzhenerka-cloud.com',
    apiTimeout: 0,
    appName: "PumpRoom-UI",
    appVersion: process.env.REACT_APP_VERSION || "0.0.0",
    appEnvironment: process.env.REACT_APP_ENVIRONMENT === "production" ? "production" : "development",
    taskSubmissionTimeout: 5 * 60 * 1000, // 5 minutes
    taskCheckStatusInterval: 1000, // in ms
};

export default config;
