import React from "react";
import { Backdrop, Box, Typography, Button } from "@mui/material";

interface ErrorModalProps {
    errorText: string | null;
    onClose: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ errorText, onClose }) => {
    return (
        <Backdrop open={true} style={{ zIndex: 1300, color: "#fff" }}>
            <Box textAlign="center" bgcolor="error.main" p={3} borderRadius={2}>
                <Typography variant="h6">{errorText || "Произошла ошибка"}</Typography>
                <Button onClick={onClose} color="inherit">
                    Закрыть
                </Button>
            </Box>
        </Backdrop>
    );
};

export default ErrorModal;
