import React, { useState, useEffect } from "react";
import {
    Typography,
    Paper,
    CircularProgress,
    Backdrop,
    Box,
    Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { TreeViewBaseItem, TreeViewItemId } from "@mui/x-tree-view/models";
import {
    TreeItem2,
    TreeItem2Props,
    TreeItem2Label,
} from "@mui/x-tree-view/TreeItem2";
import { PumpingTask } from "../types";

interface FilePanelProps {
    setSelectedFile: (selectedFile: string) => void;
    selectedFile: string | null;
    currentTask: PumpingTask | null;
}

const CustomTreeItem2Label = styled(TreeItem2Label)(({ theme }) => ({
    textWrap: "nowrap",
    overflow: "unset",
    fontSize: "0.875rem",
}));

const CustomTreeItem2 = (props: TreeItem2Props) => (
    <TreeItem2
        {...props}
        slots={{
            ...props.slots,
            label: CustomTreeItem2Label,
        }}
    />
);

interface ExtendedTreeViewBaseItem extends TreeViewBaseItem {
    isFolder?: boolean;
}

const getAllItemsWithChildrenItemIds = (items: ExtendedTreeViewBaseItem[]) => {
    const itemIds: TreeViewItemId[] = [];
    const registerItemId = (item: ExtendedTreeViewBaseItem) => {
        if (item.children?.length) {
            itemIds.push(item.id);
            item.children.forEach(registerItemId);
        }
    };

    items.forEach(registerItemId);

    return itemIds;
};

const FilePanel: React.FC<FilePanelProps> = ({
    setSelectedFile,
    selectedFile,
    currentTask,
}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    function createTree(files: string[]): {
        tree: ExtendedTreeViewBaseItem[];
        map: Map<string, ExtendedTreeViewBaseItem>;
    } {
        const tree: ExtendedTreeViewBaseItem[] = [];
        const map = new Map<string, ExtendedTreeViewBaseItem>();

        files.forEach((file) => {
            const parts = file.split("/");
            let currentLevel: ExtendedTreeViewBaseItem[] = tree;

            parts.forEach((part, index) => {
                const id = parts.slice(0, index + 1).join("/");
                let existingItem = currentLevel.find((item) => item.id === id);

                if (!existingItem) {
                    existingItem = {
                        id: id,
                        label: part,
                        children: [],
                    };
                    currentLevel.push(existingItem);
                    map.set(id, existingItem);
                }

                if (index === parts.length - 1) {
                    existingItem.isFolder = false;
                } else {
                    existingItem.isFolder = true;
                }

                currentLevel =
                    existingItem.children as ExtendedTreeViewBaseItem[];
            });
        });

        return { tree, map };
    }

    const { tree: treeItems, map: treeItemsMap } = React.useMemo(() => {
        return createTree(currentTask?.files.map((file) => file.path) || []);
    }, [currentTask?.files]);

    useEffect(() => {
        if (currentTask) {
            setLoading(false);
            if (!selectedFile && currentTask.files.length > 0) {
                if (
                    currentTask.selected_file &&
                    currentTask.files.find(
                        (file) => file.path === currentTask.selected_file
                    )
                ) {
                    setSelectedFile(currentTask.selected_file);
                } else {
                    setSelectedFile(currentTask.files[0].path);
                }
            }
        }
    }, [currentTask, selectedFile, setSelectedFile]);

    const handleFileSelect = (
        event: React.SyntheticEvent,
        fileId: string | null
    ) => {
        if (fileId) {
            const selectedItem = treeItemsMap.get(fileId);
            if (selectedItem && !selectedItem.isFolder) {
                setSelectedFile(fileId);
            }
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (!currentTask) {
        return (
            <Paper
                variant="outlined"
                style={{
                    padding: "16px",
                    height: "100%",
                    borderRadius: "16px",
                }}
            >
                <Typography variant="h6" gutterBottom>
                    Нет загруженной задачи
                </Typography>
            </Paper>
        );
    }

    return (
        <Paper
            variant="outlined"
            style={{
                paddingLeft: "8px",
                paddingRight: "8px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: "16px",
            }}
        >
            <Typography
                variant="h6"
                gutterBottom
                paddingTop={"16px"}
                marginLeft={"8px"}
            >
                <FolderOutlinedIcon
                    style={{ verticalAlign: "middle", marginRight: "8px" }}
                />
                Файлы
            </Typography>

            <div style={{ flexGrow: 1, overflow: "auto" }}>
                <RichTreeView
                    items={treeItems}
                    slots={{ item: CustomTreeItem2 }}
                    defaultExpandedItems={getAllItemsWithChildrenItemIds(
                        treeItems
                    )}
                    defaultSelectedItems={treeItems[0]?.id}
                    selectedItems={selectedFile}
                    onSelectedItemsChange={handleFileSelect}
                />
            </div>
            {error && (
                <Backdrop open={true} style={{ zIndex: 1300, color: "#fff" }}>
                    <Box
                        textAlign="center"
                        bgcolor="error.main"
                        p={3}
                        borderRadius={2}
                    >
                        <Typography variant="h6">{error}</Typography>
                        <Button onClick={() => setError(null)} color="inherit">
                            Закрыть
                        </Button>
                    </Box>
                </Backdrop>
            )}
        </Paper>
    );
};

export default FilePanel;
