import React from "react";
import { Box, Container, Alert } from "@mui/material";

interface ErrorAlertProps {
    errorText: string;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ errorText }) => {
    return (
        <Container>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100vh"
            >
                <Alert severity="error">{errorText}</Alert>
            </Box>
        </Container>
    );
};

export default ErrorAlert;
