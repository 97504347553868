export default class WindowUtils {

    // Method to get the current URL
    getHref(): string {
        return window.location.href;
    }

    // Method to get the current search query string
    getSearchParams(): URLSearchParams {
        return new URLSearchParams(window.location.search);
    }

    postMessage(message: any, targetOrigin: string): void {
        window.parent.postMessage(message, targetOrigin);
    }

    addEventListener(type: string, listener: EventListenerOrEventListenerObject): void {
        window.addEventListener(type, listener);
    }

    removeEventListener(type: string, listener: EventListenerOrEventListenerObject): void {
        window.removeEventListener(type, listener);
    }
}
