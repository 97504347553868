import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import IDE from "./IDE";
import GPTChat from "./GPTChat";
import Quiz from "./Quiz";
import ErrorAlert from "./ErrorAlert";
import ErrorModal from "./ErrorModal";
import { loadTaskFromBE, applySubmissionToBE } from "../services/taskService";
import {
    PumpingTask,
    TaskSubmissionOutput,
    IDEState,
    LoadTaskServiceParams,
} from "../types";
import { getTaskQueryParams } from "../lib/queryParams";
import useUserProfile from "../hooks/useUserProfile";

interface TaskPageProps {
    repoName: string;
    taskName: string;
}

const TaskPage: React.FC<TaskPageProps> = ({ repoName, taskName }) => {
    const [taskSubmission, setTaskSubmission] =
        useState<TaskSubmissionOutput | null>(null);
    const [loadingResult, setLoadingResult] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [initialTask, setInitialTask] = useState<PumpingTask | null>(null);
    const [currentTask, setCurrentTask] = useState<PumpingTask | null>(null);
    const [selectedFile, setSelectedFile] = useState<string | null>(null);

    const taskParams = getTaskQueryParams();

    // Use the custom hook to get the user profile
    const userProfile = useUserProfile(taskParams.demoUser);

    const onTaskLoadingStart = async () => {
        setLoadingResult(true);
        setError(null);
    };

    const onTaskLoadingEnd = async (ideState: IDEState) => {
        setLoadingResult(false);
        if (ideState.currentTask) {
            setCurrentTask(ideState.currentTask);
        }
        if (ideState.initialTask) {
            setInitialTask(ideState.initialTask);
        }
        if (ideState.selectedFile) {
            setSelectedFile(ideState.selectedFile);
        } else if (ideState.currentTask) {
            setSelectedFile(ideState.currentTask.files[0].path);
        }
        if (ideState.taskSubmission) {
            setTaskSubmission(ideState.taskSubmission);
        }
    };

    const onTaskLoadingError = async () => {
        setLoadingResult(false);
        setError("Не удалось загрузить задание. Попробуйте обновить страницу.");
    };

    const onSubmissionApplyingStart = async () => {
        setLoadingResult(true);
        setError(null);
        setTaskSubmission(null);
    };

    const onSubmissionApplyingSubmission = async (
        submission: TaskSubmissionOutput
    ) => {
        setTaskSubmission(submission);
    };

    const onSubmissionApplyingEnd = async (
        submission: TaskSubmissionOutput
    ) => {
        setLoadingResult(false);
        setTaskSubmission(submission);
    };

    const onSubmissionApplyingError = async () => {
        setError(
            "Произошла ошибка при выполнении задачи. Попробуйте обновить страницу."
        );
    };

    const loadTask = async (abortSignal: AbortSignal) => {
        const loadTaskServiceParams: LoadTaskServiceParams = {
            repoName: repoName,
            taskName: taskName,
            ignoreState: taskParams.ignoreState,
            hangOnLoad: taskParams.hangOnLoad,
        };
        await loadTaskFromBE(
            loadTaskServiceParams,
            userProfile,
            abortSignal,
            onTaskLoadingStart,
            onTaskLoadingEnd,
            onTaskLoadingError
        );
    };

    const applyTaskSubmission = async () => {
        const applySubmissionServiceParams = {
            errorOnApply: taskParams.errorOnApply,
            hangOnApply: taskParams.hangOnApply,
        };
        await applySubmissionToBE(
            applySubmissionServiceParams,
            currentTask,
            selectedFile,
            userProfile,
            onSubmissionApplyingStart,
            onSubmissionApplyingSubmission,
            onSubmissionApplyingEnd,
            onSubmissionApplyingError
        );
    };

    useEffect(() => {
        // Create abort signal to interrupt task loading
        const controller = new AbortController();
        const abortSignal: AbortSignal = controller.signal;

        let taskLoadingTimer: NodeJS.Timeout | undefined;

        if (taskName && repoName) {
            if (taskParams.errorOnLoad) {
                console.warn("Imitating error on task load");
                onTaskLoadingError();
            }
            // Load task when userProfile is defined
            if (!userProfile) {
                taskLoadingTimer = setTimeout(() => {
                    if (!userProfile) {
                        console.log("Loading task without user profile");
                        loadTask(abortSignal);
                    }
                }, 2000);
            } else {
                loadTask(abortSignal);
            }
        } else {
            onTaskLoadingError();
        }

        return () => {
            // Cancel active routine
            controller.abort();
            if (taskLoadingTimer) {
                clearTimeout(taskLoadingTimer);
            }
        };
    }, [userProfile]);

    // Render component based on the current task
    if (currentTask) {
        if (currentTask.backend === "judge0") {
            return (
                <>
                    <Box className="app" px={1} style={{ height: "100%" }}>
                        <IDE
                            taskSubmission={taskSubmission}
                            loadingResult={loadingResult}
                            currentTask={currentTask}
                            setCurrentTask={setCurrentTask}
                            selectedFile={selectedFile}
                            setSelectedFile={setSelectedFile}
                            initialTask={initialTask}
                            applyTask={applyTaskSubmission}
                        />
                    </Box>
                    {error && (
                        <ErrorModal
                            errorText={error}
                            onClose={() => setError(null)}
                        />
                    )}
                </>
            );
        } else if (currentTask.backend === "gpt") {
            return (
                <>
                    <Box px={1} className="app" style={{ height: "100%" }}>
                        <GPTChat
                            taskSubmission={taskSubmission}
                            loadingResult={loadingResult}
                            currentTask={currentTask}
                            setCurrentTask={setCurrentTask}
                            applyTask={applyTaskSubmission}
                        />
                    </Box>
                    {error && (
                        <ErrorModal
                            errorText={error}
                            onClose={() => setError(null)}
                        />
                    )}
                </>
            );
        } else if (currentTask.backend === "quiz") {
            return (
                <>
                    <Box px={1} className="app" style={{ height: "100%" }}>
                        <Quiz currentTask={currentTask} />
                    </Box>
                    {error && (
                        <ErrorModal
                            errorText={error}
                            onClose={() => setError(null)}
                        />
                    )}
                </>
            );
        } else {
            throw new Error("Unsupported backend: " + currentTask.backend);
        }
    } else {
        if (!error) {
            return (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                >
                    <CircularProgress color="secondary" />
                </Box>
            );
        } else {
            return <ErrorAlert errorText={error} />;
        }
    }
};

export default TaskPage;
