import React from "react";
import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Chip, CircularProgress } from "@mui/material";
import AnsiToHtml from "ansi-to-html";
import { TaskSubmissionOutput } from "../types";

interface ResultPanelProps {
    taskSubmission: TaskSubmissionOutput | null;
    loadingResult: boolean;
    pristine: boolean;
}

const ansiToHtml = new AnsiToHtml({
    fg: "#000000", // Default foreground color
    bg: "#FFFFFF", // Default background color if needed
});

const ResultPanel: React.FC<ResultPanelProps> = ({
    taskSubmission,
    loadingResult,
    pristine,
}) => {
    return (
        <Box marginTop={1} paddingX={2} style={{ overflow: "auto" }}>
            <Grid container>
                <Grid>
                    <Typography variant="h6" marginBottom={1}>
                        Результат выполнения
                    </Typography>
                </Grid>
                <Grid marginLeft={1}>
                    {taskSubmission && taskSubmission.status !== "running" && (
                        <Chip
                            label={taskSubmission?.status}
                            color={
                                taskSubmission?.status === "success"
                                    ? "success"
                                    : "error"
                            }
                            size="small"
                        />
                    )}
                </Grid>
            </Grid>
            {!taskSubmission ? (
                <>
                    {pristine && (
                        <Typography
                            variant="body2"
                            component={"div"}
                            marginTop={2}
                        >
                            Запустите что-нибудь, чтобы увидеть результат
                        </Typography>
                    )}
                </>
            ) : (
                <>
                    {taskSubmission.ready && (
                        <Box>
                            {taskSubmission.compile_output && (
                                <Typography
                                    variant="body2"
                                    component={"div"}
                                    marginBottom={1}
                                >
                                    <strong>Вывод компилятора:</strong>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: ansiToHtml.toHtml(
                                                taskSubmission.compile_output.trim()
                                            ),
                                        }}
                                        style={{
                                            fontFamily: "monospace",
                                            whiteSpace: "pre-wrap",
                                        }}
                                    />
                                </Typography>
                            )}
                            {taskSubmission.stdout && (
                                <Typography
                                    variant="body2"
                                    component={"pre"}
                                    marginBottom={1}
                                >
                                    <strong>Вывод:</strong>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: ansiToHtml.toHtml(
                                                taskSubmission.stdout.trim()
                                            ),
                                        }}
                                        style={{
                                            fontFamily: "monospace",
                                            // whiteSpace: "pre-wrap",
                                        }}
                                    />
                                </Typography>
                            )}
                            {taskSubmission.stderr && (
                                <Typography
                                    variant="body2"
                                    component={"pre"}
                                    marginBottom={1}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: ansiToHtml.toHtml(
                                                taskSubmission.stderr.trim()
                                            ),
                                        }}
                                        style={{
                                            fontFamily: "monospace",
                                            // whiteSpace: "pre-wrap",
                                        }}
                                    />
                                </Typography>
                            )}
                        </Box>
                    )}
                </>
            )}

            {loadingResult && !pristine && <CircularProgress sx={{ m: 2 }} />}
        </Box>
    );
};

export default ResultPanel;
