import { useState, useEffect } from 'react';
import { ProfileInput } from '../types';
import {
    setTildaProfileListener,
    removeTildaProfileListener,
    getDemoTildaProfile,
} from '../lib/tildaProfile';

const useUserProfile = (demoUser: boolean): ProfileInput | null => {
    const [userProfile, setUserProfile] = useState<ProfileInput | null>(null);

    useEffect(() => {
        let tildaProfileListener: ((event: MessageEvent) => void) | undefined;

        if (demoUser) {
            console.log("Setting up demo Tilda profile");
            setUserProfile(getDemoTildaProfile());
        } else {
            const setProfileFromTilda = (tildaProfile: ProfileInput) => {
                if (!userProfile) {
                    setUserProfile(tildaProfile);
                }
                return {};
            };
            tildaProfileListener = setTildaProfileListener(setProfileFromTilda);
        }

        return () => {
            if (tildaProfileListener) {
                removeTildaProfileListener(tildaProfileListener);
            }
        };
    }, [demoUser]);

    return userProfile;
};

export default useUserProfile;
