import WindowUtils from './windowUtils';
import { CourseInput, ProfileInput, ProfileMessage } from '../types';

const windowUtils = new WindowUtils();

export const setTildaProfileListener = (
    setUserProfile: (tildaProfile: ProfileInput) => {}
): (event: MessageEvent) => void => {
    const tildaProfileListener = createTildaProfileListener(setUserProfile) as EventListener;
    windowUtils.addEventListener("message", tildaProfileListener);
    windowUtils.postMessage({ type: "getTildaProfile" }, "*");
    return tildaProfileListener
}

export const removeTildaProfileListener = (handler: any) => {
    windowUtils.removeEventListener("message", handler);
}

export const getDemoTildaProfile = () => {
    const courseInput: CourseInput = {
        alias: "demo_user_course",
        name: "demo_user_course",
        created: new Date(2024, 0, 1, 0, 0, 0)
    };

    return {
        courses: [courseInput],
        istutor: false,
        lang: "ru",
        login: "demo_user@inzhenerka.tech",
        name: "demo_user",
        projectid: "000000"
    };
}


const createTildaProfileListener = (
    setUserProfile: (tildaProfile: ProfileInput) => {}
) => {
    return (event: MessageEvent) => {
        // Check if the message is the response from the parent window
        const message: ProfileMessage | undefined = event.data;
        if (message && message.type === "setTildaProfile") {
            // Verify the origin of the message
            if (event.origin !== "https://inzhenerka.tech") {
                console.log("Cannot get profile, unsupported origin:", event.origin);
                return;
            }
            try {
                let profile: ProfileInput = message.profile;
                profile.page_url = profile.page_url || windowUtils.getHref();
                setUserProfile(profile);
            } catch (error) {
                console.error("Error setting User profile:", error);
            }
        }
    };
}
